@charset "utf-8";
/* CSS Document */

/* sakata Sans */


/* style */

/*=================================
	Initialization of style
===================================*/

/* Open Sans */
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Lato);
/* Fjalla One */
@import url(https://fonts.googleapis.com/css?family=Fjalla+One|Open+Sans);
/* Lora */
@import url(https://fonts.googleapis.com/css?family=Lora);
/* flexslider */
@import url(flexslider.css);
/* mplus1p */
@import url(https://fonts.googleapis.com/earlyaccess/mplus1p.css);
/* notosansjapanese */
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);

//
//変数定義
$bc: #deee5b; //base-color
$sc:#4fab26;//sub-color
$bw:1200px; //base-width

//color
$c_bk:#333; //黒
$c_gy:#f7f7f7; //薄グレー
$c_wt:#fff; //白
$c_rd:#c80000; //赤



//flexの定義
@mixin flex($wrap,$justify){
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -moz-flex-flow: row $wrap;
    -webkit-flex-flow: row $wrap;
    flex-flow: row $wrap;
    -moz-justify-content: $justify;
    -webkit-justify-content: $justify;
    justify-content: $justify;
}
.flex{
    @include flex(wrap,space-between);
}
.flexno{
    @include flex(nowrap,space-between);
}
.flex-st{
    @include flex(wrap,flex-start);
}
.flexno-st{
    @include flex(nowrap,flex-start);
}
.flexd{
    @include flex(wrap,space-between);
    flex-direction: row-reverse;
    -moz-flex-direction: row-reverse;
    -webkit-flex-direction: row-reverse;
}

//title
@mixin title($font-size:32px){
    font-size:$font-size;
    font-weight: 500;
}

//img
@mixin img100{
    max-width:100%;
    height: auto;
}

//scalex
@mixin scalex($scalex){
    -webkit-transform: scaleX($scalex);
    -moz-transform: scaleX($scalex);
    -ms-transform: scaleX($scalex);
    transform: scaleX($scalex);
}

//animation
@mixin trans{
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

//arwアイコン
@mixin arw($background-size: 50px 50px){
    background: url("../images/common/arw_l.png") left center no-repeat;
    background-size: $background-size;
}
@mixin boxs{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
}

//box-shadow
@mixin box-shadow( $h: 10px , $v: 10px , $b: 0px , $s: 0px , $c: #ccc ) {
     -webkit-box-shadow: $h $v $b $s $c;
     -moz-box-shadow: $h $v $b $s $c;
     box-shadow: $h $v $b $s $c;
}

@mixin NotoSansJP{
　font-family: "Noto Sans Japanese", "ＭＳ Ｐゴシック", sans-serif;
}
@mixin M1p{
　font-family: "Mplus 1p";
}
@mixin fjalla{
    font-family: 'Fjalla One', sans-serif;
}
@mixin lora{
    font-family: 'Lora', sans-serif;
}

//メディアスクリーンの変数定義
$breakpoints: (
  'sm': 'screen and (max-width: 460px)',
  'md': 'screen and (max-width: 768px)',
  'lg': 'screen and (max-width: 960px)',
  'xl': 'screen and (max-width: 1100px)',
  'xxl': 'screen and (max-width: 1200px)',
  'pc': 'screen and (min-width: 769px)',
) !default;

@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}


/* 基本定義 */

body {
    margin: 0;
    padding: 0;
    color: $c_bk;
    font-family: "Noto Sans Japanese", "ＭＳ Ｐゴシック", sans-serif;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    background: url(../images/common/bg.jpg) center top no-repeat #fff;
    @include boxs;
    width: 100%;
    font-weight: 400;
    position: relative;
}

*, *:before, *:after {
  box-sizing: border-box;
}

a:link,
a:visited {
    text-decoration: none;
    color: $sc;
}

a:hover,
a:active {
    text-decoration: none;
    color: $bc;
}

a{
    p,li,dd{
        color: #333;
    }
}

div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd,
ul,
ol,
li,
form,
figure,
article,
section,
header,
footer {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 140%;
}

img,
a img {
    border: none;
    vertical-align: bottom;
}

h1,
h2,
h3,
h4 {
    font-size: 1em;
}

a img {
    @include trans;
}

a:hover,
a:hover img {
    opacity: 0.9;
    filter: alpha(opacity=90);
}


/* blockgroup */


/* List_group */

ul,
ol,
li {
    list-style: none;
}


/* inline-style */

address {
    font-style: normal;
    font-size: 11px;
}

address a,
address a:link,
address a:visited {
    text-decoration: none;
    color: #fff;
}

address a:hover,
address a:active {
    text-decoration: underline;
    color: #fff;
}


/* table */

table {
    width: 100%;
}
b{
    font-weight: normal;
}

/* background */
.bg_gy{ background-color: #f7f7f7;}

/* width */
.w70{
	max-width: 70%; margin-left: auto; margin-right: auto;
	@include mq(lg) {
		max-width: inherit;
	}
}

/*   margin  */
.mt0 {margin-top:0!important;}
.mt10 {margin-top:10px;}
.mt20 {margin-top:20px;}
.mt30 {margin-top:30px;}
.mt40 {margin-top:40px;}
.mt50 {margin-top:50px;}
.mt60 {margin-top:60px;}
.mt70 {margin-top:70px;}
.mt80 {margin-top:80px;}
.mt90 {margin-top:90px;}
.mt100 {margin-top:100px;}
.mb0 {margin-top:0!important;}
.mb10 {margin-bottom:10px;}
.mb20 {margin-bottom:20px;}
.mb30 {margin-bottom:30px;}
.mb40 {margin-bottom:40px;}
.mb50 {margin-bottom:50px;}
.mb60 {margin-bottom:60px;}
.mb70 {margin-bottom:70px;}
.mb80 {margin-bottom:80px;}
.mb90 {margin-bottom:90px;}
.mb100 {margin-bottom:100px;}
@include mq(md) {
	.mt10 {margin-top:5px;}
	.mt20 {margin-top:10px;}
	.mt30 {margin-top:15px;}
	.mt40 {margin-top:20px;}
	.mt50 {margin-top:25px;}
	.mt60 {margin-top:30px;}
	.mt70 {margin-top:35px;}
	.mt80 {margin-top:40px;}
	.mt90 {margin-top:45px;}
	.mt100 {margin-top:50px;}
	.mb10 {margin-bottom:5px;}
	.mb20 {margin-bottom:10px;}
	.mb30 {margin-bottom:15px;}
	.mb40 {margin-bottom:20px;}
	.mb50 {margin-bottom:25px;}
	.mb60 {margin-bottom:30px;}
	.mb70 {margin-bottom:35px;}
	.mb80 {margin-bottom:40px;}
	.mb90 {margin-bottom:45px;}
	.mb100 {margin-bottom:50px;}
}

/*=============================
   media screen base
===============================*/
//PCサイズ
@include mq(pc) { 
    .sn{
        display: block;
    }
    .pn{
        display: none !important;
    }
}

@include mq(md) { 
.pn{
    display:block;
}
.sn{
    display:none !important;
}
}

@mixin lw{
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
    @include boxs;
}

/*=============================
	Layout-Base
===============================*/
/* header
-------------------------*/
header {
    width: 100%;
    margin:0 auto;
    z-index:100;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

header article{
    text-align:left;
    padding:0px 0px 0px 0px;
    max-width: $bw;
    position: relative;
    margin:auto;
}



/* gnav -------------------------*/

.gnav {
	margin-top: 5px;
    padding-right: 210px;
	@include mq(lg){
		padding-right: 0;
	}
    @include mq(md){
        display: none;
    }
}
.gnav > ul{
    @include flex(nowrap,space-between);
    @include trans;
}
.gnav > ul > li{
    position: relative;
}
.gnav > ul > li > a{
    display:block;
    text-align:center;
    font-weight:bold;
    font-size: 15px;
    white-space: nowrap;
    color:#333;
    background-position: center 90%;
    padding:10px 20px 10px 20px;
    @include mq(xxl){
        padding:10px 12px 10px 12px;
        font-size: 14px;
    }
}


.gnav > ul > li > a:hover{

}
.gnav > ul > li > a::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0%;
    font-weight:500;
    margin-left:0px;
    width: 100%;
    height: 2px;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    background-color: $sc;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}
.gnav > ul > li > a.active::after {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
}
.gnav > ul > li > a:hover::after {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
}
.gnav > ul > li{
    &:nth-last-child(2){
        display: none;
    }
    &:last-child{
        display: none;
    }
}

.gnav .glogo{
    display: none;
}
.gnav .glogo a{
    background: url(../images/common/logo_f.jpg) center left no-repeat;
    background-size: 24px 24px;
    padding-left:30px;
}
	/* fixed */
	.gnav.fixed{
		box-shadow: 0px 1px 2px 2px rgba(0,0,0,0.05);
		position: fixed;
		opacity:1;
		top:0;
		left:0;
		background-color: #fff;
		width: 100%;
		z-index: 200;
		float: none;
		margin-top: 0px;
		padding-right: 0;
	}
	.gnav.fixed ul{
		max-width:$bw;
		margin: auto;
	}

	.gnav.fixed li .child {
		left: -50%;
	 }

	.gnav.fixed a{
		display: block;
		font-size:13px;
	}
	.gnav.fixed > ul > li > a{
		width: auto;
		padding: 17px 20px;
	}
	.gnav.fixed .glogo{
		display: block;
		width: 140px;
		min-width: 140px;
		padding: 10px 0;
		a{ padding: 0;}
		img{ @include img100;}
	}
	.gnav.fixed > ul > li{
		&:nth-last-child(2){
			display: block;
			background: $sc;
			a{
			color:#fff !important;
			}
		}
		&:last-child{
			display: block;
			background: $bc;
		}
	}

/* サブメニュー */
.gnav li .child{
    display:none;
    background-color: rgba(31,94,166,0.9);
    list-style: none;
    position: absolute;
    width: 200%;
    z-index:100 !important;
    top: 100%;
    left: -50%;
    margin: 0;
}
.gnav li ul li{
    font-size:14px;
    text-align:center;
    width: 100%;
    border-bottom:1px solid rgba(255,255,255,0.2)
}
.gnav li ul li a{
    color:#fff;
    padding:12px 0px;
    display: block;
}
.contactbtn{
    position: absolute;
    top:0;
    right:0;
    @include mq(md){ display: none;}
}
.contactbtn{
    a{
        background-size:13px;
        padding:20px 20px;
        font-size:16px;
        font-weight:bold;
        text-align: center;
        width: 142px;
        display: block;
        color:#fff;
        @include trans;
        &:hover{
            background-size:34px 26px;
            font-size:18px;
        }
        &:nth-child(1){
            background: $sc;
        }
        &:nth-child(2){
            background: $bc;
            color: #333;
        }
		@include mq(lg){
			width: 80px; padding: 12px 30px;
		}
    }
}


.subnav{
	h1{ font-size: 12px;}
    .flexno{
		padding-right: 210px; margin-top: 12px;
		.logo{
			max-width: 280px;
			img{ @include img100;}
		}
		.txt{ font-size: 18px; font-weight: 500; margin: 15px 0 0 20px;}
	}
    .tel{
        text-align: right;
        p{ font-size: 12px;}
        span{
            @include fjalla;
            display: inline-block;
            margin-top: 10px;
            padding-left:30px;
            font-weight: 200;
            font-size: 24px;
            background: url(../images/common/ic_tel.png) top -4px left no-repeat;
            background-size: 24px;
            letter-spacing: 1px;
			small{
				font-size: 13px;
			}
        }
    }
    @include mq(lg){
		padding: 15px 20px 0;
		.flexno{
			padding-right: 140px;
			.flexno-st{ margin-top: 10px;}
			.logo{ max-width: 200px;}
			.txt{ font-size: 14px; margin: 14px 0 0 15px;}
		}
		
	}
    @include mq(md){
		padding: 10px 15px;
		.flexno{
			padding-right: 0;
			.flexno-st{ margin-top: 0;}
		}
    }
    @include mq(sm){
		.flexno{
			.logo{ max-width: 180px;}
			.txt{ margin: 10px 0 0 10px;}
		}
    }
}
.subnav ul:first-child li{
    margin:0px 5px;
    a{
    background: url(../images/common/ic_arw.jpg) left center no-repeat;
    background-size: 5px 5px;
    padding:1px 10px;
    font-size:13px;
    font-weight: 400;
    color:#333;
    }
}
.subnav ul:nth-child(2) li{
    margin:0px 5px;
    a{
    background: $bc;
    padding:1px 20px;
    border-radius:20px;
    font-size:13px;
    font-weight: 400;
    color:#fff;
    text-align: center;
    }
}

/* スマホ用ナビゲーション
-------------------------*/
#header .drawer-hamburger{
	width: 1.5rem; background-color: $sc; padding: 12px .70rem 22px; top: 10px;
	
	.drawer--right &{ right: 10px;}
	.drawer-hamburger-icon,
	.drawer-hamburger-icon:after,
	.drawer-hamburger-icon:before{ height: 3px; background-color: $c_wt}
	
	/* open */
	.drawer--right.drawer-open &{ right: 16.80rem;}
	.drawer-open & .drawer-hamburger-icon{ background-color: transparent;}
}

.drawer{
	.drawer-nav{ z-index: 11;}
	.drawer-menu{ padding: 20px 0 80px;}
	.drawer-menu-list{
		text-align: left; padding: 0!important;
		> li{
			> a{
				display: block; padding: 15px 18px 18px; font-size: 16px; font-weight: 500; color: $c_bk; border-bottom: 1px solid $c_gy; position: relative;
				&::after{ content: ''; position: absolute; top: 0; bottom: 0; right: 18px; margin: auto; width: 6px; height: 6px; border-top: 2px solid $c_bk; border-right: 2px solid $c_bk; -webkit-transform: rotate(45deg); transform: rotate(45deg);}
			}
		}
	}
	.drawer-contact{
		padding: 0 15px; margin-top: 20px;
		ul{
			li{
				width: 48%;
				a{ display: block; padding: 12px 5px; font-size: 16px; font-weight: 500;}
				&.__fresh a{ background-color: $sc; color: $c_wt;}
				&.__career a{ background-color: $bc; color: $c_bk;}
			}
		}
	}
	.drawer-tel{
		margin-top: 20px;
		p{ font-weight: 500; color: $sc;}
		.tel{
			margin-top: 15px;
			a{
				display: inline-block; color: $c_bk;
				span{
					display: inline-block; font-size: 26px; letter-spacing: 1px; @include fjalla; padding-left:30px; background: url(../images/common/ic_tel.png) top -4px left no-repeat; background-size: 24px;
					small{
						font-size: 13px;
					}
				}
			}
		}
	}
}

/* container
-------------------------*/

.container-site{
    padding-left:210px;
    @include mq(lg) {
        padding: 0;
    }
}


/* footer
-------------------------*/
footer {
    background: #f5f5f5;
    width: 100%;
    text-align: left;
    color: #333;
	position: relative;
}
#footer {
	.inner{ max-width: $bw; margin: 0 auto; position: relative;}
    .logo{
       text-align: center;
       padding: 40px 0;
	   max-width: 280px;
	   margin:auto;
	   img{ @include img100;}
	   .txt{ font-size: 18px; font-weight: 500; margin-top: 10px;}
    }
	.management{ width: 55px; position: absolute; bottom: 20px; right: 0;
		figure{
			a{
				display: block; background-color: #fff; border: 1px solid #ccc; border-radius: 5px; padding: 8px;
				img{ @include img100;}
			}
		}
	}
	@include mq(lg){
		.logo{
			max-width: 200px;
			.txt{ font-size: 14px;}
		}
		.management{ width: 40px; bottom: 15px; right: 15px;}
	}
}
footer nav a {
    font-size: 13px;
    color: #333 !important;
}
footer nav {
    background: $bc;
    margin: auto;
    overflow: hidden;
    text-align: center;
    ul{
        margin: auto;
        display: inline-block;
        padding:15px 0;
        @include mq(md){
            display: block;
        }
    }
}
footer nav li {
    line-height: 140%;
    margin-bottom: 3px;
    display: inline-block;
    a{
        color: #333 !important;
        font-size: 12px;
        font-weight: bold;
        display: inline-block;
        margin: 4px 8px;
    }
    @include mq(md){
        display: block;
        padding: 1%;
        border-bottom: 1px solid rgba(255,255,255,0.6);
        a{
            display: block !important;
        }
    }
}

#copyright{
    text-align: center;
    padding: 10px;
    @include mq(md){
        margin-bottom: 50px;
    }
}


/* floating-nav
-------------------------*/
#floating-nav{
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 11;
	width: 100%;
	background-color: rgba(0,0,0,.4);
	padding: 15px 0;
	transition: .3s;
	transform: translateY(100%);
	#content-sub & .inner{
		max-width: $bw;
		margin: 0 auto;
		background-color: $c_wt;
		border: 2px solid $bc;
		border-radius: 10px;
		padding: 20px;
		text-align: left;
	}
	&.scrolled{ transform: translateY(0);}
	&.floating_recruit_list{
		.inner > .flexno{ align-items: center;}
		.left_area{
			overflow: hidden;
			& > .flex{ align-items: center;}
			.current_post_ttl{
				font-size: 14px;
				font-weight: 600;
				margin-right: 40px;
				span{
					display: block;
					&.en{
						@include lora;
						font-size: 30px;
						line-height: 1.0;
						letter-spacing: 2px;
						text-transform: uppercase;
						color: $sc;
					}
					&.jp{ margin-top: 5px;}
				}
			}
			.current_post_data{
				overflow: hidden;
				p{ font-weight: 500;}
			}
		}
		.right_area{
			margin-left: 50px;
			.btn02{
				a{
					width: 400px;
				}
			}
		}
	}
	&.floating_recruit_detail{
		.left_area{
			.current_post_ttl{
				width: 90px;
				height: 90px;
				background-color: $sc;
				color: $c_wt;
				font-weight: 500;
				float: left;
				margin-right: 20px;
				text-align: center;
				position: relative;
				span{
					position: absolute;
					top: 50%;
					left: 0;
					right: 0;
					margin: auto;
					transform: translateY(-50%);
				}
			}
			.current_post_data{
				overflow: hidden;
				.ttl{
					font-size: 16px;
					font-weight: 600;
				}
				.recruit-cat-list{
					li{ font-size: 10px; padding: 5px 10px;}
				}
			}
		}
		.right_area{
			margin-left: 50px;
			.btn02{
				a{
					width: 280px;
					font-size: 16px;
				}
			}
		}
	}
	@include mq(xxl){
		padding-left: 2%;
		padding-right: 2%;
		
		&.floating_recruit_list{
			.left_area{
				.current_post_ttl{
					margin-right: 20px;
				}
			}
			.right_area{
				margin-left: 20px;
				.btn02{
					a{
						width: 250px; font-size: 16px;
					}
				}
			}
		}
		&.floating_recruit_detail{
			.inner{
				& > .flexno{ display: block;}
			}
			.left_area{}
			.right_area{
				margin-left: 0;
				margin-top: 15px;
				.btn02{
					a{ width: 100%; max-width: inherit;}
				}
			}
		}
	}
	@include mq(md){
		padding-left: 15px;
		padding-right: 15px;
		#content-sub & .inner{
			border: none;
			border-radius: 0;
			background: none;
			padding: 0;
		}
		&.floating_recruit_list{
			.inner{
				& > .flexno{ display: block;}
			}
			.left_area{
				display: none;
			}
			.right_area{
				margin-left: 0;
				.btn02{
					a{ width: 100%; max-width: inherit;}
				}
			}
		}
		&.floating_recruit_detail{
			.left_area{
				display: none;
			}
			.right_area{
				margin-top: 0;
			}
		}
	}
}



/*=============================
    Common setting
===============================*/

.clearfix {
  *zoom: 1;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}


.detail{
    border: 1px solid #fff;
    padding:8px 45px;
    color: #fff;
    font-size:16px;
    display: inline-block;
    @include trans;
}
a:hover .detail{
    background: #fff;
    color: $bc;
}

/* pagetop
-------------------------*/

.pagetop {
    margin-top: 20px;
    text-align: right;
}

.pagetop a:before {
    content: '>';
    padding-left: 10px;
}


/* bread_crumb
-------------------------*/

.bread_crumb_list {
    width: 100%;
    left: 0;
    text-align: center;
    padding: 0px 0 0px 0;
}

.bread_crumb {
    width: $bw;
    padding: 10px;
    text-align: right;
    margin: 0 auto;
    box-sizing: border-box;
    @include mq(xxl) {
        width: 100%;
    }
}

.bread_crumb li {
    display: inline;
    color: #666;
    font-size: 0.8em;
    font-weight: 400;
}

.bread_crumb li:after {
    content: '>';
    padding-left: 10px;
    margin-right: 10px;
}

.bread_crumb li:last-child:after {
    content: '';
}

.bread_crumb li a {
    color: #666;
    text-decoration: underline;
}

.bread_crumb li a:hover {
    text-decoration: none;
}


    .bread_crumb {
        background: $bc;
        padding: 5px 10px;
        text-align: left;
    }



/* base-table
-------------------------*/

@mixin tbl{
    line-height: 180%;
    text-align: left;
    padding: 15px;
    border-bottom:1px solid #ddd;
    border-top:1px solid #ddd;
	@include mq(md){
		padding: 10px;
	}
} 
/* table */
.tbl-base {
    margin: 0 auto;
    border: none;
    width: 100%;
    border-collapse: collapse;
    th {
        @include tbl;
		text-align: center;
        background:#f5f5f5;
        vertical-align: top;
        width: 20%;
        color: $sc;
        font-weight: 600;
    }
    td {
        @include tbl;
		padding-left: 30px;
        vertical-align: middle;
    }
	@include mq(md){
		border-top:1px solid #ddd;
		th,
		td{ display: block; width: 100%; border-top: none;}
		td{ padding: 20px 0;}
	}
}

.tbl-base td dl:nth-child(n+2) {
    margin-top: 15px;
}

.tbl-base td dl dt:before {
    content: "■";
    color: #009391;
    font-size: 80%;
}

.tbl-base td dl dt,
.tbl-base td dl dd {
    line-height: 160%;
}

.clearfix:after {
    content: ".";
    display: block;
    height: 0;
    font-size: 0;
    clear: both;
    visibility: hidden;
}

/* base-layout
-------------------------*/

#content-top {
    padding-top: 10px;
    article img{
        @include img100;
    }
}

.container {
    position: relative;
    overflow: hidden;
    @include boxs;
    width: 100%;
    padding-bottom:60px;
    .inner{
        max-width: $bw;
        margin: auto;
        text-align: left;
        @include mq(md){
            padding-left:2%;
            padding-right:2%;
            @include boxs;
        }
    }
    .inner p {
        line-height: 180%;
        font-size: 14px;
    }
    a p,a li{
        color: #333;
    }
}


@include mq(lg) { 
    .container {
        padding-left:2%;
        padding-right:2%;
        width: 100%;
        .inner{
            max-width: $bw;
            margin: auto;
            text-align: left;
        }
    }
}
@include mq(xxl) {
    .container {
        padding-left:2%;
        padding-right:2%;
        width: 100%;
        @include boxs;
    }
}

/*=============================
	TopPage setting
===============================*/

/* mainimg */

.mainimg{
    width: 100%;
    margin: auto;
    text-align:left;
    position: relative;
    figure{
        width: 58%;
        min-width: 750px;
        img{
            @include img100;
        }
    }
    .maintxt{
        position: absolute;
        top: 22%;
        left: 0;
        right: 0;
        max-width: $bw;
        text-align: right;
        margin: auto;
        h2{
            font-size: 48px;
			line-height: 1.2;
            width: 52.5%;
            float: right;
            text-align: left;
            color: #333;
            span{
                background: $bc;
                display: inline-block;
                font-weight: bold;
                padding: 5px 30px 8px;
                margin-bottom: 5px;
                white-space: nowrap;
            }
        }
        p{
            clear: both;
            float: right;
            width: 35%;
            line-height: 190%;
            text-align: left;
            font-weight: bold;
            font-size: 12px;
            margin-top: 20px;
        }
    }
    @include mq(xxl){
        .maintxt{
            h2{
                font-size: 43px;
                width: 53%;
				span{ padding-left: 10px; padding-right: 10px;}
            }
        }
    }
    @include mq(xl){
        .maintxt{
            h2{
                font-size: 40px;
                width: 65%;
            }
        }
    }
    @include mq(md){
            figure{
                width: auto;
                min-width: auto;
            }
        .maintxt{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
            h2{
                width: 100% !important;
                text-align: center !important;
                font-size: 26px;
                float: none;
            }
            p{
				max-width: 500px;
                width: 90% !important;
                padding: 15px;
                background: rgba(255,255,255,0.6);
                float: none;
                margin: 20px auto 0;
            }
        }
    }
    @include mq(sm){
        .maintxt{
			top: 80%;
			transform: translateY(-80%);
            p{
				margin-top: 10px;
            }
		}
        
    }
}   


.slides{
    width: 100%;
    overflow: hidden;
}


#topContent01{
	position: relative; z-index: 1;
}

ul.content-list{
    @include flex(wrap,space-between);
    margin: 0px auto 30px;
    img{
        @include img100;
    }
    > li{
        width: 50%;
        padding:20px 0px 0;
        position: relative;
        @include boxs;
        animation-duration: 2s;
        animation-timing-function: ease-in-out;
        animation-name: contfade;
            text-align: center;
        .cont{
            width: 70%;
            background: rgba(245,242,225,0.9);
            position: absolute;
            font-weight: 500;
            font-size: 16px;
            top:40%;
            left: 0;
            right: 0;
            padding: 30px 30px 40px;
            margin: auto;
            z-index:2;
            // box-shadow:2px 2px 3px 1px rgba(0,0,0,0.1);
            @include boxs;
            @include trans;
            span{
                color: #fc534c;
                font-size:12px;
                padding-left:15px;
            }
            h3{
                font-size: 30px;
                font-weight: bold;
                letter-spacing: 1px;
                padding: 20px 0;
                &:after{
                    content:"";
                    width: 150px;
                    height: 5px;
                    background: $bc;
                    display: block;
                    margin: auto;
                }
            }
            p{
                line-height: 170%;
                text-align: left;
            }

        }
        a:hover p{
            padding: 30px 0 30px 15px;
        }
        &:nth-child(2){
            figure{
                padding-top: 150px;
            }
            
            .cont{
                 top:65%;
            }
        }
    }
    @include mq(lg) {
        .cont{
            width: 90% !important;
        }
    }

    @include mq(md) {
        width: 100%;
        margin: 0px 0px 20px;
        display: block;
        figure{
            position: relative;
            z-index: 1;
        }
        .cont{
            position: relative !important;
            margin-top:-80px !important;
            z-index: 10 !important;
        }
        > li{
            width: 96%;
            margin: 0px auto;
            p{
                font-size: 13px;
            }
            &:nth-child(2){
                figure{
                    padding-top: 20px !important;
                  }
            }
        }
        .cont{
            width: 90% !important;
        }
    }
    
}


/* topAbout */

#topAbout{
    background: url(../images/top/img03.jpg) right top no-repeat ;
    margin-top: -80px;
    text-align: left;
    position: relative;
    z-index: 0;
    height: 750px;
    h2{
        color:$sc;
        font-size: 40px;
        font-weight: bold;
        padding: 15px 0 30px 0;
    }
    padding:50px 0 100px;
    .inner{
        .left{
            width: 45%;
            margin-top: 150px;
        }
        p{
           line-height: 200%;
        }
    }
     @include mq(xxl) {
        .inner{
            .left{
                padding: 0px 20px;
                width: 60%;
                @include boxs;
            }
        }
    } 
    @include mq(lg) {
        background: url(../images/top/img03.jpg) right top no-repeat ;
        background-size: 80%;
        margin-top: 0px;
        height: auto;
        .inner{
            .left{
                width: 100%;
                padding: 60px 20px;
                margin-top: 0;
                @include boxs;
            }
        }
        h2{
            font-size: 30px;
        }
    }
    @include mq(md) {
        background: url(../images/top/img03.png) center bottom no-repeat ;
        background-size: cover;
        padding: 0;
    }
}



/* top-news */

.news-ttl{
    @include flex(nowrap,space-between);
        text-align: left;
    h3{
        width:80%;
        padding:10px 0;
        font-size: 30px;
        font-weight:600;
        @include boxs;

    }
    p{
		display: block;
		max-width: 100%;
		width: 100px;
		text-align: right;
        a{
            background: url(../images/common/arw_gr.png) left 15px no-repeat;
            background-size: 25px;
            padding:20px 0px 0px 35px;
            color: #333;
            font-weight: 600;
            display: inline-block;
        }
    }
    @include mq(md){
        h3{
            width: 60%;
        }
        p{
			width: auto;
			a{
				background-size: 15px;
				background-position: left 22px;
				padding-left: 25px;
			}
        }
    }
}


// お知らせ
//-------------------------------------------

#news{
    position: relative;
    z-index: 2;
}
.news{
    width:40%;
    margin:-90px auto -90px;
    background: $bc;
    padding: 30px;
    @include mq(lg){
        width: 80%;
    }
    @include mq(md){
        padding:20px 20px;
        margin:-40px auto 0px;
    }
}
.newslist {
    margin-top:20px;
}
.newslist li {
    @include flex(nowrap,flex-start);
    padding: 10px 20px;
    text-align: left;
    &:nth-child(odd){
        background: #f8fcde;
    }
    @include mq(md) { 
        display: block;
        padding: 8px 20px;
    }
}
.newslist p a {
    line-height: 140%;
    margin-left: 30px;
    text-align: left;
    display: block;
    text-decoration: underline;
    display: block;
    color: #333;
    padding: 2px 0;
    @include mq(md) { 
        margin-left: 0px;
    }
}
.newslist a:hover {
    text-decoration: none;
}
.newslist time {
    width: 80px;
    padding: 2px 0;
    font-weight: bold;
    line-height: 140%;
    @include mq(sm) { 
        width: 100%;
        float: none;
    }
}
.newslist li p {
    width: 80%;
    @include mq(sm) { 
        width: 100%;
        float: none;
    }
}


.bnr{
    max-width:$bw;
    @include flex(nowrap,space-between);
    margin:180px auto 60px;
    li{
        width: 48%;
        position: relative;
        margin-bottom:20px;
        a{
            padding: 30px 0;
            font-size: 25px;
            font-weight: bold;
            display: block;
            color: #333;
            background: url(../images/common/arw_gr.png) 90% center no-repeat $bc;
            background-size: 24px;
        }
    }
    @include mq(md) { 
        margin-top: 80px;
        display: block;
        li{
            width: 90%;
            margin: 10px auto;
        }
    }
}

#topContent02 {
    background: url(../images/common/bg_bc.jpg) top center repeat-x;
}


//rayout

@mixin layout($float:left,$width:50%){
    float: $float;
    width: $width;
    text-align:left;
    margin-bottom:20px;
}

@mixin subtitle($font-size:26px){
    font-size:$font-size;
    font-weight: 500;
}




.btn {
    @include trans;
    text-align: center;
    margin-top:30px;
}

.btn a{
    background: url(../images/common/arw.png) 90% center no-repeat $sc;
    display: inline-block;
    background-size:14px;
    color: #fff;
    padding: 8px 45px 8px 45px;
    margin-top:50px;
    font-weight: 500;
    @include trans;
    margin:auto;
    &:hover {
        background: url(../images/common/arw.png) 95% center no-repeat $sc ;
        opacity:0.8;
        background-size:14px;
    }
}


.btn a:hover {
    opacity: 1 !important;
}

.sbtn{
    @extend .btn;
    a{
        @include arw(15px 15px);
        font-size: 15px;
        padding: 15px 15px 15px 20px;
        margin-top:0px;
        &:hover{
            color: $bc;
        }
    }
}

.btn02{
	a{
		display: block; max-width: 400px; margin-left: auto; margin-right: auto; background-color: $sc; border: 3px solid $sc; color: $c_wt; font-size: 20px; padding: 15px; letter-spacing: 1px; position: relative; text-align: center; text-transform: capitalize; @include trans; font-family: 'Fjalla One',"Noto Sans Japanese", "ＭＳ Ｐゴシック", sans-serif;
		&::before{ content: "→ "; opacity: 0; left: 0; position: absolute; @include trans;}
		&:hover{
			 color: $sc; background-color: $c_wt;
			 &::before { opacity: 1; left: 20px; }
		}
	}
}



/* pagetop
-------------------------*/
#toTop{
	right: 20px; z-index: 10;
	a{
		width: 50px;
		height: 50px;
		display: block;
		text-align: center;
		img {
			padding-top: 5px;
			transition: all 0.3s;
			-webkit-transition: all 0.3s;
		}
		&:hover img { padding-top: 0px; opacity: 1 !important;}
	}
}

/*=============================
	Sub(Lower)Page setting
===============================*/

// ---------------------------
//common
// ---------------------------

#content-sub{
    overflow: hidden;
    background: #fff;
    position: relative;
    .inner{
        width: 100%;
        overflow:hidden;
        padding-bottom:40px;
        p{
            margin-bottom:15px;
            font-size:14px;
			&:last-child{ margin-bottom: 0;}
        }
    }
    h3{
		font-size: 26px;
    }
    h4{
    }
    @include mq(md) {
		.inner{ padding-bottom: 0;}
        h3{ font-size:20px;}
    }
}

.ttl_01{
	text-align: center;
	margin-bottom: 40px;
	
	.ttl_01_en{ @include lora; font-size: 50px; font-weight: 600; line-height: 1.0; letter-spacing: 2px; text-transform: uppercase; color: $sc;}
	#content-sub & .ttl_01_jp{ font-size: 20px; font-weight: 600; margin-top: 10px;}
	
	@include mq(md) {
		margin-bottom: 20px;
		.ttl_01_en{ font-size: 34px;}
		#content-sub & .ttl_01_jp{ font-size: 16px;}
	}
}
.ttl_02{
	font-size: 24px; font-weight: 600; margin-bottom: 20px; color: $sc; position: relative; padding-left: 50px; text-align: left;
	&::before{ content: ''; width: 30px; height: 2px; background-color: $sc; position: absolute; top: 50%; left: 0; transform: translateY(-50%);}

	@include mq(md) {
		padding-left: 40px; margin-bottom: 10px;
		&::before{ width: 25px;}
	}
}
.ttl_03{
	font-size: 24px; color: #333;
	span{ background: $bc; display: inline-block; font-weight: 600; padding: 6px 25px; margin-bottom: 5px;}
	
	@include mq(md) {
		font-size: 16px;
		span{ padding: 6px 15px;}
	}
}


.mainimg-sub{
    width: 100%;
    background: url(../images/common/bg_subttl.jpg) center center $bc;
    background-size: cover;
    position: relative;
    .inner{
        width: $bw;
        margin: auto;
        @include mq(xxl) {
            width: 100%;
        }
        @include mq(md) {

        }
        h2{
            font-size:28px;
            font-weight: 600;
            letter-spacing: 1px;
            text-align: left;
            padding:90px 0px 65px 10px;
            @include mq(md) {
                font-size:20px;
                 padding:35px 0px 30px 0px;
                 span{
                 background: $sc;
                 display: inline-block;
                 color:#fff;
                 padding:5px 10px;
                }
            }
        }
        figure{
            position: absolute;
            top:-20px;
            right:0;
            width: 70%;
            text-align: right;
        }
    }
    @include mq(lg) { 
        .inner{
            width:100%;
        }
    }
    @include mq(md) { 
        figure{
            img{
                @include img100;
            }
        }
    }
}

.col-2-wrap{
	.col-2-left{ width: 70%; float: left; margin-top: 80px;}
	.col-2-right{
		width: 24%; float: right; margin-top: 80px;
		.side-title{
			@include lora; font-size: 22px; font-weight: 600; letter-spacing: 2px; text-transform: uppercase; padding-bottom: 10px; margin-bottom: 20px; position: relative;
			&::after{ content: ""; width: 40px; position: absolute; bottom: 0; left: 0; border-bottom: 2px solid $sc;}
		}
		.post-list{
			> li{
				a{
					display: block; background-color: $c_gy; color: $c_bk; padding: 20px; @include trans;
					&:hover{
						background-color: $sc; color: $c_wt;
						*{ color: $c_wt;}
					}
					#content-sub & p{ margin-bottom: 5px;}
					h5{ font-size: 15px; font-weight: 600; line-height: 1.6;}
					.thumb{ width: 100%; height: 100px; background-color: #ccc; background-position: center center; background-repeat: no-repeat; background-size: cover;}
					.post-list-recruit-data{
						dt{ font-weight: 600; margin-bottom: 5px;}
						dd+dt{ margin-top: 10px;}
						dd{
							.shop-list{
								span{
									line-height: 160%;
									&+span{
										&::before{ content: '/'; margin-left: 5px; margin-right: 5px;}
									}
								}
							}
						}
					}
				}
				+li{ margin-top: 10px;}
			}
		}
	}
    @include mq(md) {
		.col-2-left,
		.col-2-right{ width: auto; float: none;}
		.col-2-left{ margin-top: 40px;}
		.col-2-right{
			margin-top: 60px;
			.side-title{ font-size: 20px;}
			.post-list{
				li{
					a{
						padding: 15px;
					}
				}
			}
		}
    }
}

// 1日の流れ
//-------------------------------------------
.wrap-workflow{
	.ttl_01{
		&+p{ text-align: center; font-size: 18px !important;}
	}
	@include mq(md){
		.ttl_01{
			&+p{ font-size: 14px !important;}
		}
	}
}
.flow-list{
    background: url(../images/flow/bg_timeline.jpg) repeat-y top center;
    .right{
        float: right;
    }
    .left{
        float: left;
    }
}
.flow-list figure{ 
    margin-bottom:30px;
    width: 47.25%;
    img{
        @include img100;
    }
}
.flow-list div{
    position: relative;
    &.left{
    width: 47.25%;
    }
    &.right{
    width: 47.25%;
    }
}
.flow-list dl {
    padding:20px;
    position: relative;
    background: #ffffff;
    border: 1px solid #e3e3e3;
    margin-bottom:30px;
    dd{
        line-height: 160%;
    }
}
.flow-list dl dt{
    font-size:18px;
    font-weight:500;
    margin-bottom:10px;
}
.flow-list dl dt span{
    background-color:$sc;
    font-size:15px;
    color:#fff;
    padding:3px 8px;
    display:inline-block;
    margin-right:10px;
}

.flow-list div.right:after {
    content: url(../images/flow/timeline.jpg);
    top:32px;
    left:-42px;
    position: absolute;
}
.flow-list div.left:after {
    content: url(../images/flow/timeline.jpg);
    top:32px;
    right:-42px;
    position: absolute;
}

.flow-list dl:after, .flow-list dl:before {
    top: 40px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}
.flow-list dl:after {
    border-color: rgba(255, 255, 255, 0);
    border-width: 20px;
    margin-top: -20px;
}
.flow-list dl:before {
    border-color: rgba(204, 204, 204, 0);
    border-width: 21px;
    margin-top: -21px;
}
.flow-list .left dl:after, .flow-list .left dl:before {
    left: 100%;
}

.flow-list .left dl:after {
    border-left-color: #ffffff;
}
.flow-list .left dl:before {
    border-left-color: #ccc;
}
.flow-list .right dl:after, .flow-list .right dl:before {
    right: 100%;
}
.flow-list .right dl:after {
    border-right-color: #ffffff;
}
.flow-list .right dl:before {
    border-right-color: #ccc;
}

@include mq(md) { 
    .flow-list div {
        width: 100% !important;
    }
    .flow-list figure {
        width: 100% !important;
    }
    .flow-list dl {
        width:100%;
        box-sizing:border-box;
        -moz-box-sizing:border-box;
        -webkit-box-sizing:border-box;
    }
    .flow-list .left {
       float:none;
       margin-bottom: 20px;
    }
    .flow-list .right {
       float:none;
       margin:0px;
       margin-bottom: 20px;
    }
    .flow-list div.right::after,.flow-list div.left::after{
        content:none;
    }
    .flow-list dl:after,.flow-list dl:before {
        content:none;
    }
}


// プロフィール
//-------------------------------------------
.profile{
    background: #f3f3f3;
    padding:20px 0;
    .inner{
        padding-bottom: 10px !important;
    }
    margin-bottom: 60px !important;
}

.profbox{
    dl{
        width: 55%;
        text-align: left;
        margin-top: 5px;
        dt{
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 15px;
            span{
                font-size: 16px;
                font-weight: bold;
                color: $sc;
                display: block;
            }
        }
        dd{
            line-height: 160%;
            margin-bottom: 8px;
        }
    }
    figure{
        width: 42%;
        img{
            @include img100;
        }
    }
    @include mq(md){
        display: block;
        dl{
            width: 100% !important;
        }
        figure{
            width: 90% !important;
            margin: auto;
        }
        .catch{
            font-size: 24px !important;
        }
    }
}

.profbox.flexd{
    dl{
        width: 75%;
    }
    figure{
        width: 20%;
    }
    .catch{
        background: $bc;
        font-size: 30px;
        font-weight: bold;
        display: inline-block;
        padding: 15px 30px;
        margin-bottom: 20px;
    }
}

// インタビュー
//-------------------------------------------
.wrap-interview{
	.ttl_01{
		&+p{ text-align: center; font-size: 18px !important;}
	}
	@include mq(md){
		.ttl_01{
			&+p{ font-size: 14px !important;}
		}
	}
}
.people_list_area{
	li{
		width: 17.6%; margin: 35px 3% 0 0;
		&:nth-child(5n){ margin-right: 0;}
		&:nth-child(-n+5){ margin-top: 0;}
		img{ @include img100;}
		a{
			display: block; border-right: 1px solid #eee; border-bottom: 1px solid #eee; background-color: $c_gy; color: $c_bk; position: relative;
			& *{ @include trans; opacity: 1!important;}
			figure{
				overflow: hidden;
				figcaption{ background-color: rgba(0,0,0,.8); color: $c_wt; padding: 5px 15px; position: absolute; top: -1px; right: -1px;}
			}
			.txt_area{
				padding: 20px;
				.post{ color: $sc;}
				h4{ font-size: 20px; margin-top: 10px;}
			}
			&:hover{
				figure{
					img{ -webkit-transform: scale(1.1); transform: scale(1.1);}
				}
			}
		}
		@include mq(xl){
			width: 22.7%; margin: 35px 3% 0 0!important;
			&:nth-child(4n){ margin-right: 0!important;}
			&:nth-child(-n+4){ margin-top: 0!important;}
		}
		@include mq(md){
			width: 48.5%; margin: 3% 3% 0 0!important;
			&:nth-child(-n+4){ margin-top: 3%!important;}
			&:nth-child(2n){ margin-right: 0!important;}
			&:nth-child(-n+2){ margin-top: 0!important;}
			a{
				.txt_area{
					padding: 15px;
					h4{ font-size: 16px; margin-top: 5px;}
				}
			}
		}
	}
}
.interview-list{
    .right{
        float: right;
    }
    .left{
        float: left;
    }
    figure{
        width: 40%;
        margin-bottom: 40px;
        img{
            @include img100;
        }
    }
    dl{
        margin-bottom: 40px;
    }
    dt{
        font-size: 24px;
        font-weight: bold;
        color:$sc;
        margin-bottom: 15px;
    }
    dd{
        line-height: 180%;
    }
    dl.left,dl.right{
        width: 55%;
    }
    figure img{
        width: 100%;
        height: auto;
    }
}



#content-sub .interview-list{
    @include mq(md){
        dt{
            font-size: 20px;
        }
        dl{
            width: 100%;
            float: none;
        }
        figure{
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            float: none;
        }
    }
}

// message
//-------------------------------------------
.wrap-message{
	.container{
		position: relative;
		&::after{ content: ""; width: 65%; height: 500px; background-color: $c_gy; position: absolute; top: 140px; right: 0;}
	}
	.inner{ position: relative; z-index: 1;}
	figure{
		text-align: center;
		img{ @include img100;}
	}
	h3{ font-weight: 500; line-height: 1.6;}
	.flex{
		.txt_area{
			width: 65%;
			p{font-size: 16px!important;}
			.name{
				font-size: 18px; text-align: right;
				strong{ font-size: 24px; font-weight: 400;}
			}
		}
		figure{ width: 33%;}
	}
	@include mq(md){
		.container{
			&::after{ content: ""; width: 75%; height: 250px; top: 80px;}
		}
		.flex{
			display: block;
			.txt_area{
				width: auto;
				p{font-size: 14px!important;}
				.name{
					font-size: 16px;
					strong{ font-size: 20px;}
				}
			}
			figure{ width: auto; margin-top: 30px;}
		}
	}
}

// recruit_list
//-------------------------------------------
.wrap-recruit_list{
	.sec01{
		ul{
			li{
				width: 48%;
				a{
					display: block; position: relative;
					& *{ @include trans; opacity: 1!important;}
					figure{
						overflow: hidden;
						img{ @include img100;}
					}
					.txt_area{	
						width: 300px; background-color: rgba(0,0,0,.8); padding: 30px 50px; position: absolute; bottom: -20px; left: 20px;
						h4{
							font-size: 24px; line-height: 1.4; padding-bottom: 15px; margin-bottom: 15px; position: relative;
							&::after{ content: ""; width: 30px; position: absolute; bottom: 0; left: 0; border-bottom: 2px solid #4fab26;}
						}
						h4,
						p{ color: $c_wt;}
					}
					&:hover{
						figure{
							img{ -webkit-transform: scale(1.1); transform: scale(1.1);}
						}
						.txt_area{
							background-color: rgba(222,238,91,.7);
							h4,
							p{ color: $c_bk;}
						}
					}
				}
			}
		}
		@include mq(md){
			padding-bottom: 0;
			ul{
				display: block;
				li{
					width: auto;
					&+li{ margin-top: 20px;}
					a{
						.txt_area{
							width: auto; min-width: 240px; padding: 15px 30px; left: 0; bottom: 0;
							h4{
								font-size: 20px; padding-bottom: 10px; margin-bottom: 10px;
							}
						}
					}
				}
			}
		}
	}
}
.recruit_list_area{
	.inner > article{
		@include flex(wrap,space-between); border-top: 3px solid $sc; background-color: $c_wt; box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.05); padding: 30px; margin: 6px;
		&+article{ margin-top: 50px;}
		.col-2-left{
			width: 40%;
			h3{ margin: 10px auto 0; color: $sc;}
			figure{
				position: relative; max-width: 600px; margin: 40px auto 0;
				&::after{ content: ''; width: 1px; height: 50px; background-color: #ccc; position: absolute; top: -25px; left: 0; right: 0; margin: auto;}
				img{ @include img100;}
			}
		}
		.col-2-right{
			width: 55%;
			.btn02{
				a{ max-width: 100%; font-size: 18px;}
			}
		}
	}
	@include mq(md){
		.inner > article{
			display: block; padding: 15px;
			&+article{ margin-top: 20px;}
			.col-2-left,
			.col-2-right{ width: auto;}
			.col-2-left{
				figure{
					margin: 20px auto 0;
					&::after{ height: 25px; top: -12px;}
				}
			}
			.col-2-right{
				margin-top: 15px;
				.btn02{
					a{ padding: 10px; font-size: 16px;}
				}
			}
		}
	}
}
.tbl-01{
	width: 100%; border-collapse: collapse;
	th,
	td{ padding: 10px 0; vertical-align: top; line-height: 1.8;}
	th{ width: 30%;}
	@include mq(md){
		th,
		td{ padding: 5px 0;}
		th{ width: 100px;}
	}
}
.dl-sty01 dl{
	&+dl{ margin-top: 15px;}
	dt{
		font-size: 15px; font-weight: 500; line-height: 1.8; margin-bottom: 10px; background-color: $c_gy; padding: 10px 20px;
		&::before{ content: none!important;}
	}
	dd{ line-height: 1.8;}
}
.ul-sty01{
	li{
		line-height: 1.8; position: relative; padding-left: 20px;
		&+li{ margin-top: 5px;}
		&::before{ content: ''; width: 8px; height: 8px; background-color: $bc; border-radius: 50%; position: absolute; top: 9px; left: 0;}
	}
}
.recruit-cat-list{
	margin-left: -4px; margin-right: -4px;
	li{ background-color: #333; color: #fff; padding: 5px 15px; border-radius: 5px; margin: 4px;}
	.cat-shop{
		background-color: $bc; color: $c_bk;
	}
	@include mq(md){
		li{font-size: 11px;}
	}
}


// recruit_list/detail
//-------------------------------------------
.wrap-recruit-detail{
	.col-2-left{
		.recruit-detail-date{ padding: 5px 15px; letter-spacing: 1px;}
		.recruit-detail-head{
			padding: 15px 0;
			h3{ font-weight: 600; margin-bottom: 5px;}
			border-bottom: 1px solid #ddd;
		}
		.recruit-detail-content{
			figure{
				float: left; width: 40%; margin-right: 40px;
				img{ @include img100;}
			}
			.txt_area{
				overflow: hidden;
				dl{
					dt{ font-size: 18px; font-weight: 500; margin-bottom: 10px;}
					dd{ padding-left: 20px;}
					&+dl{ margin-top: 30px;}
				}
			}
		}
		.recruit-detail-data{
			margin-top: 50px;
			h4{ font-size: 18px; font-weight: 600; background-color: #f9fbec; border-left: 5px solid $sc; padding: 15px 20px;}
			.google-maps{
				position: relative; padding-bottom: 350px; height: 0; overflow: hidden;
				iframe,
				object,
				embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
			}
			.google-maps+.tbl-base{ margin-top: 40px;}
		}
	}
	@include mq(md){
		.col-2-left{
			.recruit-detail-date{
				p{ font-size: 12px!important;}
			}
			.recruit-detail-head{
				padding: 10px 0;
			}
			.recruit-detail-content{
				figure{ float: none; width: auto; text-align: center; margin: 0 auto 10px;}
				.txt_area{
					dl{
						dt{ font-size: 16px;}
						dd{ padding-left: 15px;}
						&+dl{ margin-top: 20px;}
					}
				}
			}
			.recruit-detail-data{
				margin-top: 25px;
				h4{ font-size: 16px; border-left-width: 3px; padding: 10px 15px;}
				.google-maps{ padding-bottom: 280px;}
				.google-maps+.tbl-base{ margin-top: 20px;}
			}
		}
	}
}

// recruit_list/fresh
//-------------------------------------------
.recruit-flow-list{
	ul{
		li{
			border: 1px solid #ddd; background-color: $c_gy; padding: 40px 80px; position: relative; margin-bottom: 35px;
			&::before,
			&::after{ top: 100%; left: 50%; border: solid transparent; content: ""; height: 0; width: 0; position: absolute; pointer-events: none;}
			&::before{ border-color: rgba(47, 173, 255, 0); border-top-color: #ddd; border-width: 16px; margin-left: -16px;}
			&::after{ border-color: rgba(255, 255, 255, 0); border-top-color: $c_gy; border-width: 15px; margin-left: -15px;}
			h4{ font-size: 22px; font-weight: 500; text-align: center; margin-bottom: 20px;}
			&:last-child{
				margin-bottom: 0; background-color: $bc;
				&::before,
				&::after{ content: none;}
			}
		}
	}
	@include mq(md){
		ul{
			li{
				padding: 20px; margin-bottom: 25px;
				&::before{ border-width: 11px; margin-left: -11px;}
				&::after{ border-width: 10px; margin-left: -10px;}
				h4{ font-size: 18px;}
			}
		}
	}
}
.entry-link-area{
	padding-left: 120px; padding-right: 120px;
	.entry-link-area-inner{
		border: 1px solid #ddd; padding: 70px;
		.ttl_01{
			 .ttl_01_en{ color: $c_bk;}
		}
		p{ font-size: 18px;}
	}
	@include mq(lg){
		padding-left: 2%; padding-right: 2%;
	}
	@include mq(md){
		padding-left: 4%; padding-right: 4%;
		.entry-link-area-inner{
			padding: 30px;
			p{ font-size: 16px;}
		}
	}
}

// shops
//-------------------------------------------
.wrap-shops{
	.container{
		background: url(../images/common/bg_pattern_01.gif) repeat; padding-bottom: 120px;

		/* shops__tab */
		.shops__tab{
			&-list{
				> li{
					width: calc(100% / 2);
					> a{
						display: block;
						color: inherit;
						height: 100%;
						padding: 20px 5px;
						text-align: center;
						font-size: 18px;
						font-weight: 600;
						line-height: 1.4;
						position: relative;
						background-color: #f5f5f5;
    					border-bottom: 3px solid #EEEEED;
						transition: .3s ease-in-out;
						&::before{
							content: '';
							width: 100%;
							height: 3px;
							position: absolute;
							bottom: -3px;
							left: 0;
							right: 0;
							transition: .3s ease-in-out;
						}
					}
					> a:hover,
					&.current-cat{
						color: #4fab26;
						&::before{
							background-color: #4fab26;
						}
					}
				}
			}
			@include mq(md){
				&-list{
					> li{
						> a{
							padding: 14px 5px;
							font-size: 14px;
						}
					}
				}
			}
		}

		.dept__area{
			&:not(:last-child){
				margin-bottom: 180px;
			}
			.ttl_01{
				position: relative;
				z-index: 2;
			}
			&-inner{
				margin-top: -100px;
			}
			&-items{
				text-align: left; position: relative;
				.txt_area{
					width: 58%; float: left; margin-left: 2%; position: relative; z-index: 2; padding-top: 120px;
					.ttl{
						margin-bottom: 10px;
						a{ display: inline-block; @include trans;}
						span{
							 display: inline-block; position: relative; font-weight: 600; padding-left: 50px;
							 &::before{ content: ''; width: 30px; height: 2px; background-color: $sc; position: absolute; top: 50%; left: 0; transform: translateY(-50%);}
						}
					}
					ul{
						li{
							width: 20%;
							a{
								display: block; position: relative; opacity: 1; @include trans; 
								figure{
									position: relative;
									&::before{ content: ''; width: 100%; height: 100%; position: absolute; top: 0; right: 0; bottom: 0; left: 0; background-color: #333; opacity: .5; @include trans;}
									img{ @include img100; opacity: 1;}
								}
								.inBox{
									position: absolute; top: 50%; left: 0; right: 0; transform: translateY(-50%); text-align: center; color: $c_wt; padding-left: 5px; padding-right: 5px;
									.ttl_jp{ font-size: 18px;}
									.ttl_en{ text-transform: uppercase; font-size: 12px; letter-spacing: 1px; margin-top: 5px;}
								}
								
								&:hover{
									figure{
										&::before{ background-color: $bc; opacity: .9;}
									}
									.inBox{ color: $c_bk;}
								}
							}
						}
					}
				}
				.map{
					width: 40%; position: absolute; top: 0; right: 0; z-index: 1;
					img{ width: 100%; height: auto;}
				}
				&:nth-child(odd){
					.ttl{ text-align: right;}
					.flex-st{
						@include flex(wrap,flex-end);
					}
				}
				&:nth-child(even){
					.txt_area{
						float: right; margin-left: 0; margin-right: 2%;
					}
					.map{ right: inherit; left: 0;}
				}
			}
		}
		
		@include mq(xxl){
			padding-left: 0; padding-right: 0; padding-bottom: 60px;
			.dept__area{
				&-inner{
					margin-top: -60px;
				}
				&-items{
					.txt_area{
						padding-top: 60px;
						ul{
							li{
								width: 25%;
							}
						}
					}
				}	
			}		
		}
		@include mq(lg){
			.dept__area{
				&-items{
					.txt_area{
						ul{
							li{
								width: 33.333%;
							}
						}
					}
				}	
			}
		}
		@include mq(md){
			padding-left: 4%; padding-right: 4%;
			.dept__area{
				&:not(:last-child){
					margin-bottom: 80px;
				}
				&-inner{
					margin-top: -40px;
				}
				&-items{
					.txt_area{
						width: auto!important; float: none!important; margin-left: 0; padding-top: 12vw;
						.ttl{
							span{
								padding-left: 40px;
								&::before{ width: 25px;}
							}
						}
						ul{
							li{
								width: 50%;
							}
						}
					}
					.map{ width: 50vw; top: 0; right: 0!important; left: inherit!important;}
					&:nth-child(odd){
						.ttl{ text-align: left;}
						.flex-st{
							@include flex(wrap,flex-start);
						}
					}
					&#chiba{
						.map{ top: -13vw;}
					}
					&#hokkaido{
						.map{ top: -7vw;}
					}
				}
			}
		}
		@include mq(sm){
			.dept__area{
				&-items{
					.txt_area{
						ul{
							li{
								a{
									.inBox{
										.ttl_jp{ font-size: 16px;}
										.ttl_en{ font-size: 10px;}
									}
								}
							}
						}
					}
				}
			}		
		}
		
	}
}

// shops/detail
//-------------------------------------------
.wrap-shops-detail{
	.container{
		> article{
			position: relative;
			&::after{ content: ""; width: 80%; height: 500px; background-color: #f7f7f7; position: absolute; top: 150px; right: 0;}
			img{ @include img100;}
			&+article{ margin-top: 40px;}
			.inner{ padding-bottom: 10px!important;}
			.col-2-left{
				width: 70%; height: 400px; position: absolute; top: 0; left: 0; z-index: 1;
				.thumb{ width: 100%; height: 100%; background-color: $sc; background-position: center center; background-repeat: no-repeat; background-size: cover;}
			}
			.col-2-right{
				width: 60%; background-color: rgba(255,255,255,.95); padding: 35px 50px; position: relative; z-index: 2; margin: 100px 5px 0 auto; box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.05);
				h4{ font-size: 28px; font-weight: 500; margin-bottom: 15px;}
				h5{ font-size: 20px; font-weight: 600; margin-bottom: 0;}
				.ttl{
					position: relative;  margin: 25px auto 15px;
					.btn02{
						position: absolute; top: 0; right: 0;
						a{ width: 180px; font-size: 16px; padding-top: 5px; padding-bottom: 4px;}
					}
				}
				.tbl-01{
					th,
					td{ border-top: 1px solid #ddd; border-bottom: 1px solid #ddd; padding-top: 15px; padding-bottom: 15px;}
					th{ width: 25%;}
				}
				.google-maps{
					position: relative; padding-bottom: 280px; height: 0; overflow: hidden;
					iframe,
					object,
					embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
				}
			}
		}
		@include mq(xxl){
			padding-left: 0; padding-right: 0;
			> article{
				.inner{ padding-right: 4%;}
			}
		}
		@include mq(md){
			> article{
				&+article{ margin-top: 30px;}
				&::after{ content: none;}
				.inner{ padding-right: 4%; padding-left: 4%;}
				.col-2-left{
					width: 100%; height: 200px;
				}
				.col-2-right{
					width: 100%; padding: 20px; margin-bottom: 5px;
					h4{ font-size: 20px;}
					h5{ font-size: 16px;}
					.ttl{
						.btn02{
							top: -5px;
							a{ width: 150px; font-size: 14px; border-width: 2px;}
						}
					}
					.tbl-01{
						th{ width: 130px;}
					}
				}
			}
		}
	}
}

// company
//-------------------------------------------
.wrap-training{
	.sec01{
		position: relative;
		&::before{ content: ''; position: absolute; top: 220px; left: 0; width: 80%; height: 560px; background: url(../images/training/bg_01.jpg) no-repeat center; background-size: cover; z-index: 0;}
		.inner{ position: relative; z-index: 1;}
		.txt_area{
			background-color: rgba(249,251,234,.92); padding: 50px; margin-top: 450px;
			ul{
				width: 30.6%; margin: 0 4% 0 0;
				li{
					background: url(../images/common/ic_arw_02.png) no-repeat left 6px; padding: 0 0 0 20px; line-height: 1.8;
					&+li{ margin-top: 20px;}
				}
				
				&:nth-child(3n){ margin-right: 0;}
			}
		}
		@include mq(md){
			&::before{ top: 130px; left: 0; width: 90%; height: 300px;}
			.txt_area{
				padding: 25px; margin-top: 250px; display: block;
				ul{
					width: auto; margin: 0!important;
					li{
						&+li{ margin-top: 10px;}
					}
					&+ul{ margin-top: 10px!important;}
				}
			}
		}
	}
	.sec02{
		position: relative;
		&::after{ content: ""; width: 75%; height: 500px; background-color: $c_gy; position: absolute; top: 0; right: 0;}
		.inner{ position: relative; z-index: 1; padding-top: 60px;}
		ul{
			li{
				width: 32%; margin: 50px 2% 0 0;
				figure{
					margin-bottom: 30px; text-align: center;
					img{ @include img100;}
				}
				h4{ color: $sc; font-size: 20px; font-weight: 600; margin-bottom: 10px;}
				.btn{
					text-align: left;
					a{
						margin-left: 0; background: url(../images/common/ic_link.png) no-repeat right 5px top 5px $sc; padding-left: 25px; padding-right: 25px;
						&:hover{ opacity: .8!important;}
					}
				}
				
				&:nth-child(3n){ margin-right: 0;}
				&:nth-child(-n+3){ margin-top: 0;}
			}
		}
		@include mq(md){
			background-color: $c_gy;
			&::after{ content: none;}
			.inner{ padding-top: 40px;}
			ul{
				display: block;
				li{
					width: auto; margin: 0!important;
					figure{ margin-bottom: 15px; }
					h4{ font-size: 16px;}
					
					&+li{ margin-top: 30px!important;}
				}
			}
		}
	}
	.sec03{
		.time_line_area{
			position: relative; overflow: hidden;
			&::before{ content: ''; position: absolute; left: calc(50% - 3px); top: 0; content: ""; width: 6px; height: 100%; background-color: $bc;}
			.box{
				overflow: hidden; position: relative;
				.date_area_wrap{
					 width: 80px; position: absolute; top: 50%; left: 0; right: 0; transform: translateY(-50%); margin: auto;
					.date_area_inner{
						width: 80px; height: 80px; border-radius: 50%; background-color: $bc; margin: auto; position: relative;
						.inBox{
							position: absolute; top: 50%; left: 0; right: 0; transform: translateY(-50%); margin: auto; text-align: center; font-size: 20px; font-weight: 600; line-height: 1.2;
							span{ font-size: 10px; display: block; text-transform: capitalize; margin-top: 5px;}
						}
					}
					.flom_area{ width: 39px; height: 27px; margin: 20px auto; background: url(../images/common/ico_flom.png) no-repeat center;}
				}
				.flex{ align-items: center; min-height: 80px;}
				.txt_area{
					width: 50%;
					dl{
						dt{ color: $sc; font-size: 20px; font-weight: 600; line-height: 1.6;}
						dd{ font-size: 15px;  margin-top: 25px; line-height: 1.6;}
					}
				}
				.img_area{
					width: 50%; text-align: center;
					img{ @include img100; width: 100%;}
					figcaption{ font-weight: 600; margin-top: 10px;}
				}
				
				.flex.img_left{
					flex-direction: row-reverse;
					div:first-child{ padding-left: 80px; padding-right: 150px;}
					div:nth-child(2){ padding-left: 150px; padding-right: 80px;}
				}
				.flex.img_right{
					div:first-child{ padding-left: 150px; padding-right: 80px;}
					div:nth-child(2){ padding-left: 80px; padding-right: 150px;}
				}
				.pl0{ padding-left: 0!important;}
				.pr0{ padding-right: 0!important;}
				&+.box{ margin-top: 30px;}
			}
		}
		@include mq(xl){
			.time_line_area{
				.box{
					.flex.img_left{
						div:first-child{ padding-right: 0;}
						div:nth-child(2){ padding-left: 0;}
					}
					.flex.img_right{
						div:first-child{ padding-left: 0;}
						div:nth-child(2){ padding-right: 0;}
					}
				}
			}
		}
		@include mq(md){
			padding-top: 50px;
			.time_line_area{
				&::before{ left: 27px;}
				.box{
					.date_area_wrap{
						 width: 60px; top: 0; left: 0; right: inherit; transform: translateY(0%); margin: 0;
						.date_area_inner{
							width: 60px; height: 60px; 
							.inBox{
								font-size: 17px;
								span{ font-size: 8px; margin-top: 2px;}
							}
						}
						.flom_area{ width: 39px; height: 27px;}
					}
					.flex{
						display: block; min-height: 60px; padding-left: 70px; padding-top: 20px;
						div{ padding: 0!important;}
					}
					.txt_area{
						width: auto;
						dl{
							dt{ font-size: 16px;}
							dd{ font-size: 13px;  margin-top: 15px;}
						}
					}
					.img_area{ width: auto; margin-top: 20px;}
					&+.box{ margin-top: 30px;}
				}
			}
		}
	}
	.sec04{
		position: relative;
		&::after{ content: ""; width: 65%; height: 500px; background-color: $c_gy; position: absolute; top: 0; left: 0;}
		.inner{ position: relative; z-index: 1; padding-top: 60px;}
		.left_area{
			.ttl_01{ text-align: left; margin-bottom: 0;}
		}
		.right_area{
			ul{
				li{
					width: calc(100% / 3 - 2%);
					&:not(:nth-child(3n)){
						margin-right: 3%;
					}
					&:not(:nth-child(-n+3)){
						margin-top: 4%;
					}
					figure{
						margin-bottom: 10px;
						img{ @include img100;}
					}
					h4{
						font-size: 15px; line-height: 1.6; /* margin-bottom: 15px; */ position: relative; padding-left: 25px;
						&::before{ content: ''; position: absolute; top: 11px; left: 0; width: 15px; height: 2px; background-color: $sc;}
					}
				}
			}
		}
		@include mq(md){
			&::after{ width: 80%; height: 300px;}
			.inner{ padding-top: 40px;}
			.left_area{
				.ttl_01{ text-align: center; margin-bottom: 20px;}
			}
			.right_area{
				ul{
					li{
						width: calc(100% / 2 - 2%)!important;
						margin: 0!important;
						&:not(:nth-child(2n)){
							margin-right: 4%!important;
						}
						&:not(:nth-child(-n+2)){
							margin-top: 4%!important;
						}
						h4{
							font-size: 13px; /* margin-bottom: 10px; */ padding-left: 20px;
							&::before{ top: 9px; width: 10px;}
						}
					}
				}
			}
		}
	}
}

// company
//-------------------------------------------
.wrap-company{
	.sec02{
		position: relative;
		&::after{ content: ""; width: 70%; height: 780px; background-color: $c_gy; position: absolute; top: 0; left: 0;}
		.inner{
			z-index: 1; position: relative;
			.txt_area{
				width: 50%; margin-top: 90px; float: left;
				.ttl_01{ text-align: left;}
				dl{
					overflow: hidden;
					&+dl{ margin-top: 15px;}
					dt{
						width: 170px; float: left; font-size: 16px; font-weight: 800; @include lora; text-transform: capitalize; letter-spacing: 2px; padding-bottom: 10px; position: relative;
						&::after{ content: ''; width: 30px; height: 2px; background-color: $bc; position: absolute; bottom: 0; left: 0;}
					}
					dd{ overflow: hidden; font-size: 16px; font-weight: 500;}
				}
			}
			.img{
				width: 45%; margin-top: 150px; float: right;
				img{ @include img100;}
			}
		}
		@include mq(md){
			background-color: $c_gy;
			&::after{ content: none;}
			.inner{
				z-index: 1; position: relative;
				.txt_area,
				.img{ width: auto; margin-top: 0; float: none;}
				.txt_area{
					margin-top: 40px;
					.ttl_01{ text-align: center;}
					dl{
						dt{ width: 110px; font-size: 14px;}
						dd{ font-size: 14px;}
					}
				}
				.img{ margin-top: 25px;}
			}
		}
	}
	.sec03{
		ul{
			li{
				margin: 4px 0;
				&+li{
					&::before{ content: '／'; margin: 0 5px;}
				}
			}
		}
	}
}

// trainer
//-------------------------------------------
.wrap-trainer{
	.sec01{
		ul{
			li{
				width: 32%; margin: 0 2% 0 0;
				a{
					display: block; border-right: 1px solid #eee; border-bottom: 1px solid #eee; background-color: #f7f7f7; color: #333;
					&:hover{
						figure{
							img{ transform: scale(1.1); -webkit-transform: scale(1.1);}
						}
					}
				}
				figure{
					 overflow: hidden;
					img{ @include img100;}
				}
				.txt_area{
					padding: 20px;
					.date{}
					h4{ font-size: 17px; margin-top: 5px;}
				}
				&:nth-child(3n){ margin-right: 0;}
			}
		}
		@include mq(md){
			ul{
				display: block;
				li{
					width: 100%; margin: 0;
					figure{
						img{ width: 100%;}
					}
					&+li{ margin-top: 3%;}
					.txt_area{
						padding: 15px;
						h4{ font-size: 16px;}
					}
				}
			}
		}
	}
	.sec02{
		position: relative;
		&::after{ content: ""; width: 70%; height: 65%; background-color: $c_gy; position: absolute; top: 75px; left: 0;}
		.inner{ z-index: 1; position: relative;}
		.img{
			width: 45%; float: right;
			img{ @include img100;}
		}
		.txt_area{
			width: 50%; float: left; padding-top: 50px;
			p{ font-size: 16px!important;}
		}
		.img_area{
			margin-top: 40px;
			figure{
				width: 32%; margin: 20px 2% 0 0;
				img{ @include img100;}
				&:nth-child(3n){ margin-right: 0;}
				&:nth-child(-n+3){ margin-top: 0;}
			}
		}
		@include mq(md){
			background-color: $c_gy; padding-top: 60px; margin-bottom: 60px;
			&::after{ content: none;}
			.img{ width: auto; float: none;}
			.txt_area{
				width: auto; float: none; padding-top: 0; margin-top: 30px;
				p{ font-size: 14px!important;}
			}
			.img_area{ margin-top: 20px;}
		}
	}
	.sec03{
		position: relative;
		&::after{ content: ""; width: 70%; height: 60%; background-color: $c_gy; position: absolute; top: 75px; right: 0;}
		.inner{ z-index: 1; position: relative;}
		.img{
			width: 45%; float: left;
			img{ @include img100;}
		}
		.txt_area{
			width: 50%; float: right; overflow: hidden; padding-top: 50px;
			.ttl_03{
				br+span{ margin-left: 90px;}
			}
			p{ font-size: 16px!important;}
		}
		.img_area{
			margin-top: 40px;
			figure{
				width: 32%; margin: 20px 2% 0 0;
				img{ @include img100;}
				&:nth-child(3n){ margin-right: 0;}
				&:nth-child(-n+3){ margin-top: 0;}
			}
		}
		@include mq(md){
			&::after{ width: 90%; height: 92%; top: 20px;}
			.img{ width: auto; float: none;}
			.txt_area{
				width: auto; float: none; padding-top: 0; margin-top: 30px;
				.ttl_03{
					br+span{ margin-left: 60px;}
				}
				p{ font-size: 14px!important;}
			}
			.img_area{ margin-top: 20px;}
		}
	}
	.sec04{
		article{
			width: 48%;
			figure{
				position: relative;
				img{ @include img100;}
				figcaption{
					background-color: rgba(0, 0, 0, 0.8); color: #fff; padding: 20px; position: absolute; bottom: 0; left: 0; right: 0;
					.post{
						span{ display: inline-block; background-color: #4fab26; padding: 5px 15px;}
					}
					.name{ font-size: 20px!important; font-weight: 600; margin-top: 10px;}
					.other{ margin-top: 5px;}
				}
			}
			.txt_area{
				background-color: #F6F3E4; padding: 25px; margin-top: 40px; position: relative;
				&::before{
					content: ""; position: absolute; width: 20px; bottom: 100%; left: 0; right: 0; margin: auto; border: 20px solid transparent; border-bottom: 20px solid #F6F3E4;
				}
			}
		}
		@include mq(md){
			.inner > .flex{ display: block;}
			article{
				width: auto;
				figure{
					img{ width: 100%;}
				}
				&+article{ margin-top: 30px;}
			}
		}
		@include mq(sm){
			article{
				figure{
					figcaption{
						padding: 15px;
						.post{
							span{ padding: 2px 10px;}
						}
						.name{ font-size: 16px!important;}
						.other{ margin-top: 5px;}
					}
				}
				.txt_area{
					padding: 15px; margin-top: 30px;
					&::before{
						width: 15px; border: 15px solid transparent; border-bottom: 15px solid #F6F3E4;
					}
				}
			}
		}
	}
}

// faq
//-------------------------------------------
.wrap-faq{
	.ttl_01{
		&+p{ text-align: center; font-size: 18px!important;}
	}
	dl{
		.faq-ico{ font-size: 40px; font-weight: 700; @include fjalla; line-height: 1; float: left; color: $sc; margin-right: 30px;}
		.faq-body{ padding-top: 10px; padding-bottom: 10px; font-size: 16px; overflow: hidden;}
	
		&.accIgnite > dt,
		&.accIgnite > dd{ overflow: hidden; padding: 20px 50px;}
		&.accIgnite > dt{
			background-color: #f9fbec; padding: 20px 100px 20px 50px; position: relative; cursor: pointer;
			.accordionIcon{ display: inline-block; position: absolute; top: 0; bottom: 0; right: 40px; margin: auto; width: 35px; height: 35px; background-color: $sc;}
				.accordionIcon::before,
				.accordionIcon::after{ content: ""; position: absolute; top: 0; bottom: 0; transition: all .3s;}
				.accordionIcon::after{ top: 6px; right: 8px; width: 8px; height: 8px; border-top: 2px solid #fff; border-right: 2px solid #fff; -webkit-transform: rotate(45deg); transform: rotate(45deg);}
				.accordionIcon::before,
				.accordionIcon::after{ top: 16px; right: 8px; width: 50%; height: 2px; background-color: #fff; border: none;}
				.accordionIcon::before{ -webkit-transform: rotate(90deg); transform: rotate(90deg);}
				.accordionIcon::after{ -webkit-transform: rotate(0deg); transform: rotate(0deg);}
		}
		&.accIgnite > dd{
			background-color: $c_gy;
			.faq-ico{ color: #ccc;}
		}
		&.accIgnite > dd+dt{ margin-top: 1em;}
		
		/* hover */
		dt.active .accordionIcon::before{ -webkit-transform: rotate(0deg); transform: rotate(0deg);}
		dt.active .accordionIcon::after{ display: none;}
	}

	@include mq(md){
		.ttl_01{
			&+p{ font-size: 14px!important;}
		}
		dl{
			&.accIgnite > dt,
			&.accIgnite > dd{ overflow: hidden; padding: 15px;}
			&.accIgnite > dt{
				padding-right: 50px;
				.accordionIcon{ right: 15px; margin: auto; width: 30px; height: 30px; background-color: $sc;}
				.accordionIcon::before,
				.accordionIcon::after{ top: 14px; right: 7px;}
			}
			.faq-ico{ font-size: 32px; margin-right: 15px;}
			.faq-body{ font-size: 14px; padding-top: 6px; padding-bottom: 6px;}
			&.accIgnite > dd+dt{ margin-top: .5em;}
		}
	}
}

// topics
//-------------------------------------------
.wrap-news{}
.newsListArea{
	dl{
		padding: 25px; overflow: hidden;
		
		&:nth-child(odd){ background-color: #f8fcde;}
		
		dt{ width: 120px; float: left; font-weight: 600;}
		dd{
			overflow: hidden;
			a{ color: #333;}
		}
	}
	@include mq(md){
		dl{
			padding: 15px;
			dt{ width: auto; float: none; margin-bottom: 5px;}
		}
	}
}
.wrap-news-detail{
	.col-2-left{
		h3{ font-weight: 600; margin-bottom: 20px;}
		.date{ text-align: right; margin-bottom: 20px;}
	}
}


// entry（mw-wp-form）
//-------------------------------------------
.wrap-entry{
	.step_bar{
		max-width: 600px; position: relative; margin-left: auto; margin-right: auto; text-align: center;
		li{
			width: 33.333%; position: relative;
			.num{
				@include lora; font-size: 20px; font-weight: 600; width: 45px; height: 45px; background-color: #ccc; border-radius: 50%; color: $c_wt; position: relative; margin: 0 auto; letter-spacing: 2px;
				span{ display: block; position: absolute; top: 50%; left: 0; right: 0; transform: translateY(-50%);}
			}
			.ttl{ font-size: 16px; font-weight: 500; margin-top: 10px;}
			&+li::before{ content: ""; background-color: #ccc; width: calc(100% - 45px); height: 4px; position: absolute; left: calc(-50% + 22px); top: 22px;}
			
			/* current */
			&.current{
				.num{ background-color: $sc;}
				.ttl{ color: $sc;}
				&::before{ background-color: $sc;}
			}
		}
		@include mq(md){
			li{
				.num{ font-size: 18px; width: 40px; height: 40px;}
				.ttl{ font-size: 14px; margin-top: 5px;}
				&+li::before{ width: calc(100% - 40px); left: calc(-50% + 20px); top: 19px;}
			}
		}
	}
}
.mw_wp_form{
	h4{ background-color: $c_bk; color: $c_wt; font-size: 20px; font-weight: 500; padding: 10px 20px 12px; margin: 50px auto 15px;}
	.must{
		margin-top: .5em;
		span{ display: inline-block; background-color: $c_rd; color: #fff; font-size: 12px; font-weight: normal; padding: 2px 10px;}
	}
	
	table {
		width: 100%; border-collapse: collapse;
		tbody tr th,
		tbody tr td { border-top:1px solid #ddd; border-bottom:1px solid #ddd; padding: 20px;}
		tbody tr th {
			width: 28%;
			vertical-align: top;
			background-color: $c_gy;
			padding-right: 15px;
		}
		tbody tr td { vertical-align: middle;}
	}
	
	input[type="text"],
	input[type="email"],
	textarea {
		width: 100%;
		line-height: 150%;
		padding: 8px 10px;
		background: #fff;
		border: solid 1px #ddd;
		resize: vertical;
		-webkit-appearance: none;
		border-radius: 0;
	}
		input[type="text"]:focus,
		input[type="email"]:focus,
		textarea:focus { outline: 0; border: 1px solid $bc;}
	#zip1,
	#zip2{ width: auto!important;}
	.search-select{
		display: inline-block; overflow: hidden; position: relative;
		&::before { content: ''; position: absolute; top: 50%; right: 8px; transform: translateY(-50%); width: 0; height: 0; border-left: 5px solid transparent; border-right: 5px solid transparent; border-top: 5px solid $c_bk; pointer-events: none; z-index: 1;}
		select {
			padding-right: 1em; cursor: pointer; text-indent: 0.01px; text-overflow: ellipsis; outline: none; box-shadow: none; -webkit-appearance: none; appearance: none; padding: 8px 38px 8px 10px; position: relative; border: 1px solid #ddd; border-radius: 2px; background: #fff; font-size: 14px; font-family: "Noto Sans Japanese", "ＭＳ Ｐゴシック", sans-serif;
			&::-ms-expand { display: none;}	
		}
	}
	.form-birthday{
		input[type="text"]{ width: auto; margin-right: 5px;}
		span{
			display: inline-block;
			&+span{ margin-left: 10px;}
		}
	}
	.contact-btn {
		text-align: center; margin-top: 3em;
		button[type="submit"] {
			-webkit-appearance: none;
			font-size: 20px;
			letter-spacing: 2px;
			color: #fff;
			border: 2px solid $c_bk;
			background: $c_bk;
			cursor: pointer;
			padding: .8em 4em;
			margin: 0 10px;
			outline: none;
			position: relative;
			@include trans;
			font-family: "Noto Sans Japanese", "ＭＳ Ｐゴシック", sans-serif;
			
			&::after{
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				right: 15px;
				width: 6px;
				height: 6px;
				margin: auto;
				border-top: 1px solid #fff;
				border-right: 1px solid #fff;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
			}
			
			&:hover{
				background: #fff; color: $c_bk;
				&::after { border-color: $c_bk;}
			}
		}
		button[name="submitBack"]{
			border-color: #aaa; background: #aaa;
			&:hover{ color: #aaa;}
		}		
	}
	
	@include mq(md){
		h4{ font-size: 16px; padding: 10px 15px 12px; margin-top: 30px;}
		table {
			border-top: 1px solid #ddd;
			tbody tr th,
			tbody tr td { display: block; width: 100%; border-top: none; padding: 15px;}
			tbody tr th{
				position: relative;
				.must{
					position: absolute; top: 6px; right: 15px;
					span{ font-size: 12px;}
				}
			}
			tbody tr td{ padding-left: 0; padding-right: 0px;}
		}
		
		input[type="text"],
		input[type="email"],
		textarea { font-size: 16px;}
		
		.contact-btn{
			margin: 2em 0 0;
			button[type="submit"] { width: 100%; font-size: 16px; padding: .6em; margin: 0; }
			button[name="submitBack"] {margin-top: .8em; }
		}
	}
}
/* 確認画面の時 */
.mw_wp_form.mw_wp_form_confirm{
	.dl-sty01{
		dt{ display: none;}
	}
}
		

/* wp-pagenavi
-------------------------*/
.wp-pagenavi {
	clear: both; text-align:center; line-height: 150%; margin-top: 50px;
	a,
	span { display: inline-block; color: #351705; background-color: #FFF; border: solid 1px #e2e0dc; padding: 8px 15px; margin: 0 2px; white-space: nowrap; border-radius: 3px; -webkit-transition: 0.2s ease-in-out; -moz-transition: 0.2s ease-in-out; -o-transition: 0.2s ease-in-out; transition: 0.2s ease-in-out; text-align: center; text-decoration:none;}
	a:hover{ color:#79860a; border-color:#a3b414;}
	span.current{ background-color: #deee5b; border-color: #deee5b; font-weight: 600;}
	@include mq(md){
		margin-top: 2.5em;
		a,
		span{ padding: 5px 8px;}
		span.pages{ display: block; margin-bottom: 5px; border: none; background: none;}
	}
}


